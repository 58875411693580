import { useFormik } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import * as yup from 'yup';
import { dispatchToast, validateCPF } from '../../../../utils';
import { useAuthContext } from '@auth/index';
import { useCallback, useState } from 'react';
import { useFirstAccessContext } from '../../firstAccessContext';
import { useSearchParams } from 'react-router-dom';
import { CustomMfa } from '@/auth/models/SessionUserModel';
import { trpc } from '@/api/client';

export const useCreateAccountForm = () => {
  const { setStep, step, setFormData, invitationToken } =
    useFirstAccessContext();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const auth = useAuthContext();
  const [searchParams] = useSearchParams();
  const mfaMethod = searchParams.get('mfaMethod') as CustomMfa | undefined;

  const { mutateAsync: validateInvitationMutation } =
    trpc.validateInvitationToken.useMutation({
      onError: () => {
        throw new Error(
          'Ops! Erro ao processar dados, favor tentar novamente!',
        );
      },
    });

  const form = useFormik({
    initialValues: {
      name: '',
      documentNumber: '',
      email: '',
      phoneNumber: '',
      password: '',
      passwordConfirmation: '',
    },
    validationSchema: validationSchema,
    validate: (values: FormValues) => {
      const errors: any = {};
      if (values.documentNumber && !validateCPF(values.documentNumber))
        errors.documentNumber = 'Favor digitar um CPF válido.';

      if (
        values.phoneNumber &&
        values.phoneNumber.replace(/\D/g, '').length < 11
      )
        errors.phoneNumber = 'Favor digitar um Telefone válido.';

      return errors;
    },
    onSubmit: async (values: FormValues) => {
      setIsLoading(true);
      try {
        await validateInvitationToken(values.documentNumber.replace(/\D/g, ''));
        await signUp(values);
      } catch (error) {
        dispatchToast({
          type: 'error',
          content: error?.message,
        });
      } finally {
        setIsLoading(false);
      }
    },
  });

  const validateInvitationToken = useCallback(
    async (validationAttribute: string) => {
      if (!invitationToken) {
        return;
      }
      const { isValid } = await validateInvitationMutation({
        invitationToken,
        validationAttribute,
      });
      if (!isValid) {
        throw new Error(
          'Dados inválidos. Confira novamente os dados preenchidos e tente novamente.',
        );
      }
    },
    [invitationToken],
  );

  const signUp = async (values: FormValues) => {
    try {
      const username = uuidv4();
      await auth.signUp({
        password: values.password,
        phoneNumber: `+55${values.phoneNumber.replace(/\D/g, '')}`,
        name: values.name,
        email: values.email,
        username,
        customMfa: mfaMethod,
      });
      setFormData({ username, ...values });
      setStep(step + 1);
    } catch {
      throw new Error('Ops! Erro ao processar dados, favor tentar novamente!');
    }
  };

  return { form, isLoading };
};

type FormValues = {
  name: string;
  documentNumber: string;
  email: string;
  phoneNumber: string;
  password: string;
  passwordConfirmation: string;
};

const validationSchema = yup.object({
  name: yup.string().required('Favor digitar um nome'),
  documentNumber: yup.string().required('Favor digitar um CPF'),
  email: yup.string().email('Digite um email válido').notRequired(),
  phoneNumber: yup.string().required('Favor digitar um celular válido'),
  password: yup
    .string()
    .min(8)
    .matches(RegExp(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])((?=.*\W)|(?=.*_))^[^ ]+$/))
    .required('Favor digitar uma senha'),
  passwordConfirmation: yup
    .string()
    .required('Favor confirmar a sua senha')
    .oneOf([yup.ref('password'), null], 'As senhas precisam ser idênticas'),
});
