import { Navigate, useSearchParams } from 'react-router-dom';
import { ChooseAccessPage } from './components/ChooseAccess';
import {
  ContentContainer,
  LeftContainer,
  PageContainer,
  RightContainer,
  Wrapper,
} from './styles';
import { Footer, Header } from '@components/Common';
import { useRedeemInvitation } from './hooks';
import { useEffect } from 'react';

export interface AccessOption {
  id: string;
  name: string;
  registrationNumber: string;
}

export const PageAccessSelection = () => {
  const [params] = useSearchParams();
  const { isLoading, redeem } = useRedeemInvitation();
  const invitationToken = params.get('invitationToken');

  useEffect(() => {
    if (invitationToken) {
      redeem({ invitationToken });
    }
  }, [invitationToken]);

  const redirectUrl = params.get('redirectTo');

  if (redirectUrl && decodeURIComponent(redirectUrl).includes('signup')) {
    return <Navigate replace={true} to={decodeURIComponent(redirectUrl)} />;
  }

  return (
    <PageContainer>
      <Header />
      <ContentContainer>
        <Wrapper>
          <LeftContainer>
            {invitationToken || isLoading ? <></> : <ChooseAccessPage />}
          </LeftContainer>
          <RightContainer imageUrl="https://images.flashapp.com.br/flash-os/authentication/list-accesses-page.png" />
        </Wrapper>
      </ContentContainer>
      <Footer />
    </PageContainer>
  );
};
