import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  padding: 60px 0px 0px;
  margin: -5%;
`;

export const ContentBox = styled.div`
  width: 100%;
  min-height: 500px;
  border: 1px solid;
  border-color: var(--color-neutral-90);
  border-radius: 8px;
`;

export const AlertWrapper = styled.div`
  margin: -7% auto;
  width: 125px;
  height: 125px;
  display: flex;
  align-items: center;
  justify-content: center;

  background: var(--color-secondary-90);
  border-radius: 50%;
`;

export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-width: 420px;
  text-align: center;
  margin: 15% 25%;
`;

export const ResendWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  margin-top: -14%;
  margin-left: 40%;
  margin-right: 40%;
  max-width: 150px;
`;

export const LogoutWrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: auto;
  max-width: 80px;
  padding: 10px;
`;

export const GoBackWrapper = styled.div`
  display: flex;
  flex-direction: row;
  text-align: center;
  margin-top: -10%;
  margin-left: 40%;
  margin-right: 40%;
  max-width: 180px;
`;

export const Subtitle = styled.span`
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;

  color: var(--color-neutral-50);
`;

export const SubtitleBold = styled.span`
  font-weight: 700;
  font-size: 16px;
  font-style: bold;
  line-height: 22px;

  color: var(--color-neutral-50);
`;
