import { useState, useCallback, useEffect } from 'react';
import { dispatchLogout } from '../../../../utils';
import { GreetingsText, Title, SubTitle, TextsContainer } from './styles';
import { AccessSelectionList } from './components/AccessSelectionList';
import { useAuthContext, useCurrentAuthenticatedUser } from '@auth/index';
import { useUserAccessesQuery } from '../../hooks';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAuthNavigation } from 'src/routes';
import { AccessOption } from '../../types';
import { EmptyState } from '../EmptyState';

export const ChooseAccessPage = () => {
  const [params] = useSearchParams();
  const { accesses, isLoading } = useUserAccessesQuery();
  const authNavigate = useAuthNavigation();
  const navigate = useNavigate();
  const { user } = useCurrentAuthenticatedUser();
  const authProvider = useAuthContext();
  const [preSelectedAccess, setPreSelectedAccess] = useState<
    AccessOption | undefined
  >(undefined);

  const onAccessTokenReceived = useCallback(() => {
    const redirectUrl = params.get('redirectTo');
    if (redirectUrl) {
      navigate(decodeURIComponent(redirectUrl));
    } else {
      navigate('/home');
    }
  }, [navigate, params]);

  const checkIfUserIsLogged = useCallback(async () => {
    let authUser: any;
    try {
      authUser = await authProvider.currentAuthenticatedUser();
    } catch {}
    if (!authUser) {
      authNavigate('LOGIN');
    }
  }, [authProvider, authNavigate]);

  useEffect(() => {
    checkIfUserIsLogged();
    if (accesses?.length === 1) {
      setPreSelectedAccess(accesses[0]);
    }
    const ssoProviderName = params.get('ssoProviderName');
    const employeeId = params.get('employeeId');
    const paramsAccess = accesses?.find((a) => {
      if (ssoProviderName)
        return a.ssoProvider?.providerName === ssoProviderName;
      return a.employeeId === employeeId;
    });
    if (paramsAccess) {
      setPreSelectedAccess(paramsAccess);
    }
  }, [accesses, checkIfUserIsLogged, params]);

  if (accesses?.length === 0 && !isLoading) {
    return <EmptyState />;
  }

  return (
    <>
      <>
        <TextsContainer>
          <GreetingsText variant="body3">
            {user?.attributes?.name ? `Olá, ${user?.attributes?.name}!` : ''}
          </GreetingsText>
          <Title variant="headline5">
            Qual conta você gostaria de acessar?
          </Title>
          <SubTitle variant="body3">
            Veja abaixo as organizações em que você possui uma conta de acesso
            ao app e plataforma Flash.
          </SubTitle>
        </TextsContainer>
        <AccessSelectionList
          preSelectedAccess={preSelectedAccess}
          onGoBack={dispatchLogout}
          callback={onAccessTokenReceived}
        />
      </>
    </>
  );
};
