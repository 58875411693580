import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { BigDataCorpQuiz } from '../../api';
import { dispatchToast } from '../../utils';
import { trpc } from '@api/client';
import { useFlag } from '@flash-tecnologia/feature-flags';
import { CustomMfa } from '@/auth/models/SessionUserModel';

export const usePageCustomFirstAccessQuiz = () => {
  const customMfaEnabled = useFlag({
    flagName: 'FLASH_OS_CUSTOM_MFA_ENABLED',
  });
  const location = useLocation();
  const navigate = useNavigate();
  const [step, setStep] = useState(0);
  const [quiz, setQuiz] = useState<BigDataCorpQuiz>();
  const [searchParams] = useSearchParams();
  const [answers, setAnswers] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const cpf = searchParams.get('cpf') || '';
  const companyName = searchParams.get('companyName') || '';
  const mfaMethod = searchParams.get('mfaMethod') as CustomMfa | undefined;

  const isContinueButtonDisabled =
    step !== 0 && answers[step - 1] === undefined;

  useEffect(() => {
    if (location.state) {
      const { quiz } = location.state as {
        cpf: string;
        quiz: BigDataCorpQuiz;
        companyName: string;
      };
      if (!quiz) {
        navigate('/authentication/login');
      } else {
        setQuiz(quiz);
      }
    } else {
      navigate('/authentication/login');
    }
  }, [location.state, navigate, companyName, cpf]);

  const { mutateAsync: getInvitationByCpfAndCompany } =
    trpc.getInvitationByCpfAndCompany.useMutation({
      onError: () => {
        dispatchToast({
          type: 'error',
          content:
            'Ocorreu um erro, por favor, tente novamente mais tarde ou entre em contato com o suporte.',
        });
      },
    });

  const handleOnClickNext = async () => {
    if (step === quiz?.Questions.length) {
      try {
        setIsLoading(true);
        const token = await getInvitationByCpfAndCompany({
          cpf,
          companyName,
          ticketId: quiz?.TicketId,
          answers,
        });
        if (token) {
          if (customMfaEnabled || mfaMethod === 'whatsapp') {
            navigate(
              `/authentication/first-access?invitationToken=${token}&mfaMethod=whatsapp`,
            );
          } else {
            navigate(`/authentication/first-access?invitationToken=${token}`);
          }
        }
      } catch {
        dispatchToast({
          type: 'error',
          content:
            'Os dados informados não conferem. Entre em contato com o seu RH e tente novamente.',
        });
        navigate('/authentication/login', {
          state: { quiz: undefined, cpf: undefined, companyName: undefined },
        });
      } finally {
        setIsLoading(false);
      }
    } else {
      setStep((prevStep) => prevStep + 1);
    }
  };

  const handleOnClickBack = () => {
    if (step === 0) {
      navigate('/authentication/login');
    } else {
      setStep((prevStep) => prevStep - 1);
    }
  };

  const handleOnSelectAnswer = (answer: string) => {
    setAnswers((prevAnswers) => {
      const newAnswers = [...prevAnswers];
      newAnswers[step - 1] = answer;
      return newAnswers;
    });
  };

  return {
    step,
    quiz,
    cpf,
    answers,
    isContinueButtonDisabled,
    isLoading,
    handleOnClickNext,
    handleOnClickBack,
    handleOnSelectAnswer,
  };
};
